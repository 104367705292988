<template lang="pug">
  div
    v-container(fill-height
                fluid
                grid-list-xl
                style="margin:0; padding:15px")
        v-layout(justify-center
                wrap)
            v-flex(md12)
                h2 Página não encontrada
</template>

<script>

export default {
  created() {
    this.$store.dispatch('auth/verify');
  },
}

</script>
